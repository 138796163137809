<template>
    <div class="bg-color">
        <Navbar />
        <Header :category="'visitors'" :bannerTitle="bannerTitle" :breadCrumb="borrowSpaceCrumb" />
        <ExhibitionPageBody />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import ExhibitionPageBody from './ExhibitionPageBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'

    export default {
        name: 'ExhibitionMain',
        components: {
            Navbar,
            Header,
            ExhibitionPageBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: "ACTIVITY<br>EXTENSION",
                borrowSpaceCrumb: [
                    {
                        name: this.$t('navbar.menu_1'),
                        path: "/visitors-exhibition"
                    },
                    {
                        name: this.$t('navbar.menu_1_1'),
                    }
                ]
            }
        }
    }
</script>
