var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "blog-details-area ptb-100" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-8 col-md-12" }, [
          _c("div", { staticClass: "blog-details-desc" }, [
            _c("div", { staticClass: "article-content" }, [
              _c("div", { staticClass: "entry-meta" }, [
                _c("ul", [
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("leftBody.date")))]),
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v(_vm._s(_vm.postDate))
                    ])
                  ])
                ])
              ]),
              _c("h3", [_vm._v(_vm._s(_vm.title))]),
              _c(
                "div",
                { staticClass: "article-image" },
                [
                  _c(
                    "b-carousel",
                    {
                      staticStyle: { "text-shadow": "1px 1px 2px #333" },
                      attrs: {
                        id: "carousel-1",
                        interval: 3000,
                        controls: "",
                        indicators: "",
                        background: "#ababab",
                        "img-width": "1024",
                        "img-height": "720"
                      }
                    },
                    _vm._l(_vm.data.picture, function(item, index) {
                      return _c("b-carousel-slide", {
                        key: index,
                        attrs: { "img-src": item.path }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "article-footer" }, [
                _c("div", { staticClass: "article-tags" }, [
                  _vm._m(0),
                  _c("a", [_vm._v(_vm._s(_vm.$t("leftBody.share")))])
                ]),
                _c("div", { staticClass: "article-share" }, [
                  _c("ul", { staticClass: "social" }, [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://www.facebook.com/sharer.php?u=" +
                              _vm.currentURL +
                              "/&quote=我想分享活動",
                            target: "_blank"
                          }
                        },
                        [_c("i", { staticClass: "bx bxl-facebook" })]
                      )
                    ]),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://twitter.com/share?text=我想分享活動&url=" +
                              _vm.currentURL,
                            target: "_blank"
                          }
                        },
                        [_c("i", { staticClass: "bx bxl-twitter" })]
                      )
                    ]),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://social-plugins.line.me/lineit/share?url=" +
                              _vm.currentURL,
                            target: "_blank"
                          }
                        },
                        [_c("i", { staticClass: "fab fa-line" })]
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", {
                staticClass: "blog-content-text mt-4 ck-content",
                domProps: { innerHTML: _vm._s(_vm.content) }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "col-lg-4 col-md-12" }, [
          _c(
            "div",
            { staticClass: "widget-area", attrs: { id: "secondary" } },
            [
              _c("div", { staticClass: "widget widget_categories" }, [
                _c("h3", { staticClass: "widget-title" }, [
                  _vm._v(_vm._s(_vm.$t("rightBody.title_1")))
                ]),
                _c("div", { staticClass: "post-wrap" }, [
                  _c(
                    "ul",
                    [
                      _c("li", [
                        _c("a", [_vm._v(_vm._s(_vm.$t("rightBody.title_2")))]),
                        _c("p", { staticClass: "exhibition-content" }, [
                          _vm._v(_vm._s(_vm.periodTime))
                        ])
                      ]),
                      _c("li", [
                        _c("a", [_vm._v(_vm._s(_vm.$t("rightBody.title_3")))]),
                        _c("p", { staticClass: "exhibition-content" }, [
                          _vm._v(_vm._s(_vm.location))
                        ])
                      ]),
                      _c(
                        "li",
                        [
                          _c("a", [
                            _vm._v(_vm._s(_vm.$t("rightBody.title_4")))
                          ]),
                          _vm._l(_vm.data.tag, function(item, index) {
                            return _c(
                              "b-badge",
                              { key: index, attrs: { variant: "info mr-2" } },
                              [_vm._v(" " + _vm._s(item.tag_name))]
                            )
                          })
                        ],
                        2
                      ),
                      _c("li", [
                        _c("a", [_vm._v(_vm._s(_vm.$t("rightBody.title_5")))]),
                        _c("p", { staticClass: "exhibition-content" }, [
                          _vm._v(_vm._s(_vm.organizer))
                        ])
                      ]),
                      _vm.contactor != ""
                        ? _c("li", [
                            _c("a", [
                              _vm._v(_vm._s(_vm.$t("rightBody.title_6")))
                            ]),
                            _c("p", { staticClass: "exhibition-content" }, [
                              _vm._v(_vm._s(_vm.contactor))
                            ])
                          ])
                        : _vm._e(),
                      _vm._l(_vm.flexContent, function(item, index) {
                        return _c("li", { key: index }, [
                          _c("a", [_vm._v(_vm._s(Object.keys(item)[0]))]),
                          _c("p", { staticClass: "exhibition-content" }, [
                            _vm._v(
                              " " + _vm._s(item[Object.keys(item)[0]]) + " "
                            )
                          ])
                        ])
                      })
                    ],
                    2
                  )
                ])
              ]),
              _c("div", { staticClass: "widget widget-peru-posts-thumb" }, [
                _c("h3", { staticClass: "widget-title" }, [
                  _vm._v(_vm._s(_vm.$t("rightBody.title_7")))
                ]),
                _c(
                  "div",
                  { staticClass: "post-wrap" },
                  _vm._l(_vm.hotEventData, function(item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "thumb",
                          attrs: {
                            href:
                              "/visitors-exhibition/event/" +
                              item.activity_event_id
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "fullimage cover",
                            style: {
                              backgroundImage:
                                "url('" + encodeURI(item.path) + "')"
                            },
                            attrs: { role: "img" }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "info" }, [
                        _c("time", { attrs: { datetime: "2020-06-30" } }, [
                          _vm._v(_vm._s(item.periodTime))
                        ]),
                        _c("h4", { staticClass: "title usmall" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/visitors-exhibition/event/" +
                                  item.activity_event_id
                              }
                            },
                            [_vm._v(" " + _vm._s(item.event_title) + " ")]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "clear" })
                    ])
                  }),
                  0
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "bx bx-share-alt" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }