<template>
  <div class="blog-details-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="blog-details-desc">
            <div class="article-content">
              <div class="entry-meta">
                <ul>
                  <li>
                    <span>{{ $t("leftBody.date") }}</span>
                    <a href="#">{{ postDate }}</a>
                  </li>
                </ul>
              </div>
              <h3>{{ title }}</h3>

              <div class="article-image">
                <b-carousel
                  id="carousel-1"
                  :interval="3000"
                  controls
                  indicators
                  background="#ababab"
                  img-width="1024"
                  img-height="720"
                  style="text-shadow: 1px 1px 2px #333;"
                >
                  <b-carousel-slide
                    :img-src="item.path"
                    v-for="(item, index) in data.picture"
                    :key="index"
                  >
                  </b-carousel-slide>
                </b-carousel>
              </div>
              <div class="article-footer">
                <div class="article-tags">
                  <span><i class="bx bx-share-alt"></i></span>

                  <a>{{ $t("leftBody.share") }}</a>
                </div>

                <div class="article-share">
                  <ul class="social">
                    <li>
                      <a
                        :href="
                          `https://www.facebook.com/sharer.php?u=${currentURL}/&quote=我想分享活動`
                        "
                        target="_blank"
                      >
                        <i class="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          `https://twitter.com/share?text=我想分享活動&url=${currentURL}`
                        "
                        target="_blank"
                      >
                        <i class="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        :href="
                          `https://social-plugins.line.me/lineit/share?url=${currentURL}`
                        "
                        target="_blank"
                      >
                        <i class="fab fa-line"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="blog-content-text mt-4 ck-content" v-html="content"></div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-12">
          <div class="widget-area" id="secondary">
            <div class="widget widget_categories">
              <h3 class="widget-title">{{ $t("rightBody.title_1") }}</h3>
              <div class="post-wrap">
                <ul>
                  <li>
                    <a>{{ $t("rightBody.title_2") }}</a>
                    <p class="exhibition-content">{{ periodTime }}</p>
                  </li>
                  <li>
                    <a>{{ $t("rightBody.title_3") }}</a>
                    <p class="exhibition-content">{{ location }}</p>
                  </li>
                  <li>
                    <a>{{ $t("rightBody.title_4") }}</a>
                    <b-badge
                      variant="info mr-2"
                      v-for="(item, index) in data.tag"
                      :key="index"
                    >
                      {{ item.tag_name }}</b-badge
                    >
                  </li>
                  <li>
                    <a>{{ $t("rightBody.title_5") }}</a>
                    <p class="exhibition-content">{{ organizer }}</p>
                  </li>
                  <li v-if="contactor != ''">
                    <a>{{ $t("rightBody.title_6") }}</a>
                    <p class="exhibition-content">{{ contactor }}</p>
                  </li>
                  <li v-for="(item, index) in flexContent" :key="index">
                    <a>{{ Object.keys(item)[0] }}</a>
                    <p class="exhibition-content">
                      {{ item[Object.keys(item)[0]] }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="widget widget-peru-posts-thumb">
              <h3 class="widget-title">{{ $t("rightBody.title_7") }}</h3>
              <div class="post-wrap">
                <div
                  class="item"
                  v-for="(item, index) in hotEventData"
                  :key="index"
                >
                  <a
                    :href="
                      `/visitors-exhibition/event/${item.activity_event_id}`
                    "
                    class="thumb"
                  >
                    <span
                      class="fullimage cover"
                      role="img"
                      :style="{
                        backgroundImage: `url('${encodeURI(item.path)}')`,
                      }"
                    ></span>
                  </a>
                  <div class="info">
                    <time datetime="2020-06-30">{{ item.periodTime }}</time>
                    <h4 class="title usmall">
                      <a
                        :href="
                          `/visitors-exhibition/event/${item.activity_event_id}`
                        "
                      >
                        {{ item.event_title }}
                      </a>
                    </h4>
                  </div>

                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";
import * as CLE from "assets/plugins/ClickLinkEvent";
export default {
  name: "BlogDetails",
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      apiUrl: `${process.env.VUE_APP_API_URL}/activity`,
      title: "",
      content: "",
      periodTime: "",
      postDate: "",
      location: "",
      organizer: "",
      contactor: "",
      flexContent: [],
      data: {},
      hotEventData: {},
      currentURL: window.location.href,
    };
  },
  mounted() {
    this.getData();
    this.getHotEvent();
    const activity_event_id = this.$route.params.activity_event_id;
    CLE.addClickLinkCount(
      this,
      "event/" + activity_event_id,
      "activity",
      "activity_event_id",
      activity_event_id
    );
  },
  methods: {
    getData() {
      const activity_event_id = this.$route.params.activity_event_id;
      const api = `${this.apiUrl}/event?activity_event_id=${activity_event_id}&language_id=${this.$i18n.locale}`;
      this.axios.get(api).then((response) => {
        this.data = response["data"];
        this.title = this.data["event_title"][this.$i18n.apiLang];
        this.content = this.data["event_content"][this.$i18n.apiLang];
        this.postDate = DateFormat(
          new Date(Date.parse(this.data["post_date"].replace(/-/g, "/"))),
          "yyyy-mm-dd"
        );
        let startTime = DateFormat(
          new Date(Date.parse(this.data["start_time"].replace(/-/g, "/"))),
          "yyyy-mm-dd"
        );
        let endTime = DateFormat(
          new Date(Date.parse(this.data["end_time"].replace(/-/g, "/"))),
          "yyyy-mm-dd"
        );
        this.periodTime =
          startTime == endTime ? endTime : startTime + " ~ " + endTime;
        this.location = this.data["location"][this.$i18n.apiLang];
        this.organizer = this.data["organizer"][this.$i18n.apiLang];
        this.contactor = this.data["contact_person"][this.$i18n.apiLang];
        let content = "";
        try {
          content = JSON.parse(this.data["flex_content"][this.$i18n.apiLang]);
        } catch (e) {
          // Oh well, but whatever...
        }
        this.flexContent = Array.isArray(content) ? content : [];
        let tags = [];
        this.data.tag.forEach((element) => {
          if (element.tag_group == this.$i18n.locale) {
            tags.push(element);
          }
        });
        this.data.tag = tags;
      });
    },
    getHotEvent() {
      const api = `${this.apiUrl}/event_list`;
      let currentTime = DateFormat(new Date(), "yyyy-mm-dd");
      let postParams = {
        activity_id: 1,
        page: this.page,
        limit: "5",
        order_by: [
          {
            field: "start_time",
            method: "ASC",
          },
        ],
        filters: {
          time_range: {
            start: currentTime,
          },
        },
        language_id: this.$i18n.locale,
        image_width: 100,
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      this.axios.post(api, postParams, postConfig).then((response) => {
        let hotEvent = response["data"]["list"];

        hotEvent.forEach((element) => {
          let startTime = DateFormat(
            new Date(Date.parse(element["start_time"].replace(/-/g, "/"))),
            "yyyy-mm-dd"
          );
          let endTime = DateFormat(
            new Date(Date.parse(element["end_time"].replace(/-/g, "/"))),
            "yyyy-mm-dd"
          );
          element.periodTime =
            startTime == endTime ? endTime : startTime + " ~ " + endTime;
          element.event_title = element.event_title[this.$i18n.apiLang];
        });
        this.hotEventData = hotEvent;
      });
    },
  },
};
</script>

<style scoped>
.social i {
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
}
.blog-details-area
  .blog-details-desc
  .article-footer
  .article-share
  .social
  li
  a {
  width: 35px;
  height: 35px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li span {
  color: #000066;
  font-size: 14px;
}

.blog-details-area .blog-details-desc .article-content h3 {
  color: #000033;
  font-size: 30px;
}
.blog-details-area .blog-details-desc .article-content .article-image {
  margin-bottom: 10px;
}
.blog-details-area .blog-details-desc .article-footer {
  margin-top: 15px;
}
.blog-content-text {
  color: #929292;
}

.exhibition-content {
  margin-top: 6px;
  color: #929292;
}
.widget-area {
  margin-top: 35px;
}
.badge-info {
  background-color: #33ccff;
  padding: 8px 10px;
  margin-top: 8px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 500;
}
.blog-details-area .widget-area .widget_categories ul li {
  font-weight: normal;
}
.blog-details-area .widget-area .widget_categories ul li::before {
  background: #33ccff;
}
</style>
